
import { defineComponent, reactive, ref, toRefs, onMounted, watch, watchEffect } from 'vue'
import { ElMessage } from 'element-plus'
import { setAddAPI, getRoleList } from '../api'
import { toResult } from '@/utils/toResult'
import { isCompelOption } from '../config'
export default defineComponent({

  props: {
    modelValue: Boolean,
    rowData: Object
  },
  emits: ['update:modelValue', 'refresh'],
  setup(props, { emit }) {
    const data = reactive({
      formRef: ref<HTMLElement | null>(null),
      formData: ref<any>({
        price: 1.2,
        withholdingRate: 1.5,
        isCompel: 1,
        carBasicAnnualFee: '0.00',
        carChargeAnnualFee: '0.00'
      }),
      isDialog: props.modelValue,
      isCompelOption
    })
    const options = ref([])
    const rules = {
      uuid: [
        { required: true, message: '必填', trigger: 'blur' },
        {
          pattern: /^[a-zA-Z0-9]{3,32}$/,
          message: '只支持输入3-32位数字或字母组合',
          trigger: 'blur'
        }
      ],
      roleId: [
        { required: true, message: '必填', trigger: 'change' }
      ],
      title: [
        { required: true, message: '必填', trigger: 'blur' },
        { min: 1, max: 32, message: '只支持输入1-32位', trigger: 'blur' }
      ],
      formalPeriod: [
        { required: true, message: '必填', trigger: 'blur' },
        {
          pattern: /^(?!00)(?:[0-9]{1,4})$/,
          message: '只支持输入0-3650',
          trigger: 'blur'
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (value > 3650) {
              callback(new Error('不能大于3650'))
            } else {
              callback()
            }
          },
          trigger: 'blur'
        }
      ],
      basicAnnualFee: [
        { required: true, message: '必填', trigger: 'blur' },
        {
          pattern: /^(0|\+?[1-9][0-9]{0,5})(\.\d{1,2})?$/,
          message: '只支持输入0-999999，最多2位小数',
          trigger: 'blur'
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (value > 999999) {
              callback(new Error('只支持输入0-999999，最多2位小数'))
            } else {
              callback()
            }
          },
          trigger: 'blur'
        }
      ],
      price: [
        { required: true, message: '必填', trigger: 'blur' },
        {
          pattern: /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/,
          message: '只支持输入0-10，最多2位小数',
          trigger: 'blur'
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (value > 10) {
              callback(new Error('不能大于10'))
            } else {
              callback()
            }
          },
          trigger: 'blur'
        }
      ],
      trialPeriod: [
        { required: true, message: '必填', trigger: 'blur' },
        {
          pattern: /^(?!00)(?:[0-9]{1,3})$/,
          message: '只支持输入0-180',
          trigger: 'blur'
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (value > 180) {
              callback(new Error('不能大于180'))
            } else {
              callback()
            }
          },
          trigger: 'blur'
        }
      ],
      carBasicAnnualFee: [
        { required: true, message: '必填', trigger: 'blur' },
        {
          pattern: /^(0|\+?[1-9][0-9]{0,5})(\.\d{1,2})?$/,
          message: '只支持输入0-100，最多2位小数',
          trigger: 'blur'
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (value > 100) {
              callback(new Error('不能大于100'))
            } else {
              callback()
            }
          },
          trigger: 'blur'
        }
      ],
      carChargeAnnualFee: [
        { required: true, message: '必填', trigger: 'blur' },
        {
          pattern: /^(0|\+?[1-9][0-9]{0,5})(\.\d{1,2})?$/,
          message: '只支持输入0-100，最多2位小数',
          trigger: 'blur'
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (value > 100) {
              callback(new Error('不能大于100'))
            } else {
              callback()
            }
          },
          trigger: 'blur'
        }
      ],
      withholdingRate: [
        { required: true, message: '必填', trigger: 'blur' },
        {
          pattern: /^(0|\+?[1-9][0-9]{0,5})(\.\d{1,2})?$/,
          message: '只支持输入0-2之间，最多2位小数',
          trigger: 'blur'
        },
        {
          validator: (rule: any, value: any, callback: any) => {
            if (value > 2) {
              callback(new Error('不能大于2'))
            } else {
              callback()
            }
          },
          trigger: 'blur'
        }
      ],
      carChargeDailyFee: [
        { required: true, message: '必填', trigger: 'blur' },
        {
          pattern: /^(0|\+?[1-9][0-9]{0,5})(\.\d{1,2})?$/,
          message: '只支持输入数字，最多2位小数',
          trigger: 'blur'
        }
      ],
      newRoleId: [
        { required: true, message: '必填', trigger: 'blur' }
      ]
    }

    watchEffect(() => {
      // 单车扣费/天 = 单车基础年费 / 365  精确2位小数。可以为0，可修改。
      data.formData.price = ((Number(data.formData.carBasicAnnualFee) / 365).toFixed(2)) || 0.00
      // if (isNaN(data.formData.price)) data.formData.price = 0

      // 单车收费扣费/天 = 单车收费年费 / 365 精确2位小数。可以为0，可修改。
      data.formData.carChargeDailyFee = (Number(data.formData.carChargeAnnualFee) / 365).toFixed(2) || 0.00
      // if (isNaN(data.formData.carChargeDailyFee)) data.formData.carChargeDailyFee = 0
    })

    const getOption = async() => {
      const [err, res] = await toResult(getRoleList({
        roleType: 1
      }))
      if (err) return false
      options.value = res.voList
    }
    // 关闭弹框
    const handleCloseDialog = () => {
      const ref: any = data.formRef
      ref.resetFields()
      emit('update:modelValue', false)
    }

    // 保存提交数据
    const submitData = () => {
      const ref: any = data.formRef

      ref.validate(async(valid: boolean) => {
        if (!valid) {
          return false
        }
        const params = {
          ...data.formData
        }
        const [err, res, msg] = await toResult(setAddAPI(params))
        if (err) return false
        console.log(res)
        ElMessage.success(msg)
        emit('refresh')
        handleCloseDialog()
      })
    }
    watch(() => props.modelValue, (n) => {
      data.isDialog = n
      n && getOption()
    })
    onMounted(() => {
      // getOption()
    })

    return {
      ...toRefs(data),
      options,
      rules,
      handleCloseDialog,
      submitData
    }
  }
})
